(function() {
    //Not Working to test
    $.subscribe('form.submitted', function(){
        $('.notification').fadeIn(500).delay(1000).fadeOut(500);
    });

    $.subscribe('reset-notification-msg', function(){
        $('.notification').html('Done !');
    });

    $(document).on("click", ".make-div-clickable", function(){
        window.location = $(this).find("a").attr("href");
        return false;
    });

    $(document).on("click", ".make-div-clickable-new-tab", function(){
        var pageLink = $(this).find("a").attr("href");
        window.open(pageLink, '_blank');
        return false;
    });

    $(document).on("click", ".make-div-clickable-new-tab-same-window", function(){
        var pageLink = $(this).find("a").attr("href");
        window.open(pageLink, '_self');
        return false;
    });

    $('.cycle').cycle({
      fx: 'fade',
      timeout: 15000
    });

})();

